import { t } from '@lingui/macro';
import { QuickShopTrayView } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import { CartContext } from 'components/cartContext/CartContext';
import { CustomerContext } from 'components/customerContext/CustomerContext';
import { FeatureFlagsContext } from 'components/featureFlagsProvider/FeatureFlagsContext';
import Overlay from 'components/overlay/Overlay';
import { OverlayContext } from 'components/overlay/OverlayContext';
import { useProductsByTitleQuery } from 'utils/hooks/useProductsByTitleQuery';

export const QuickShopTray = ({ title }: { title: string }) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const cartContext = useContext(CartContext);
  const customerContext = useContext(CustomerContext);
  const featureFlagsContext = useContext(FeatureFlagsContext);
  const overlayContext = useContext(OverlayContext);

  if (cartContext === undefined)
    throw new Error('QuickShopTray must be used within a CartContext');

  if (customerContext === undefined)
    throw new Error('QuickShopTray must be used within a CustomerContext');

  if (featureFlagsContext === undefined)
    throw new Error(
      'QuickShopTray Button must be used within a FeatureFlagsContext'
    );

  const { cartLineAddV2, cartLoading, quickAddedProductId, cart } = cartContext;
  const { featureFlags } = featureFlagsContext;
  const { amazonCart } = featureFlags[locale];
  const { proCustomer } = customerContext;

  /**
   * If product title has ' - ' use the fist part of title in query
   */
  const [sanitizedTitle] = title.split(' - ');

  const { data: products, isLoading } = useProductsByTitleQuery(title, locale);

  // Cart line items variant ids.
  // Used to check if product is already on cart or not.
  const cartVariantsIds = cart?.lines.map((line) => line.merchandise.id) ?? [];

  const overlayNamespace = 'quickShopTray';

  /**
   * Close the overlay on unmount.
   * - Fixes issue where overlay is not closed on route change as the component
   *   is already unmounted.
   */
  useEffect(() => {
    return () => {
      overlayContext.close(overlayNamespace);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay
      type="mobile-drawer"
      blockClass="quickShopOverlay"
      direction="left"
      namespace={overlayNamespace}
      title={t({
        id: 'quickShopTray.title',
        message: 'Quick Shop',
      })}
    >
      <QuickShopTrayView
        title={sanitizedTitle}
        products={products}
        proCustomer={proCustomer}
        amazonCart={amazonCart}
        loading={isLoading}
        cartLoading={cartLoading}
        handleAdd={cartLineAddV2}
        quickAddedProductId={quickAddedProductId}
        cartVariantsIds={cartVariantsIds}
      />
    </Overlay>
  );
};

export default QuickShopTray;
