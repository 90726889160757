import { Trans } from '@lingui/react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import {
  AddToCart,
  Button,
  ProductPrice,
  QuickAddToCartStates,
} from '../../../components';
import type { StoreMoney } from '../../../types.d';
import { ProductCardBadge } from '../../cards/product/ProductCardBadge';
import productPriceStyles from '../../ui/productPrice/ProductPrice.module.scss';
import styles from './InlineProductCard.module.scss';

export type InlineProductCardProduct = {
  systemName: string;
  title: string;
  handle: string;
  id: string;
  image?: string;
  images?: {
    // there is more but we only need alt and other types would be hard to match with parent type
    alt?: string | null;
  }[];
  price: StoreMoney;
  tags?: string[];
  proOnly?: boolean;
  url: string;
  availableForSale: boolean;
  firstAvailableVariantId?: string;
  ultaProductUrl?: string;
  customBuyNowLabel?: string;
  customBuyNowUrl?: string;
  asin?: string;
  barcode?: string;
  slogan?: string;
};

export type InlineProductCardProps = {
  product?: InlineProductCardProduct;
  isLoading?: boolean;
  className?: string;
  showDetailsLink?: boolean;
  handleAdd?: (payload: { id: string; quantity: number }) => void;
  proCustomer?: boolean;
  amazonCart?: boolean;
  quickAddToCartState?: QuickAddToCartStates;
  variantAlreadyInCart?: boolean;
  showPrice?: boolean;
  cartEnabled?: boolean;
  mikmakEnabled?: boolean;
};

export const InlineProductCard = (props: InlineProductCardProps) => {
  const {
    product,
    proCustomer,
    amazonCart,
    isLoading,
    quickAddToCartState = QuickAddToCartStates.Active,
    variantAlreadyInCart = false,
    cartEnabled = true,
    showPrice = true,
    mikmakEnabled = false,
  } = props;

  if (!product || isLoading) {
    return <InlineProductCardLoading {...props} />;
  }

  return (
    <InlineProductCardActive
      {...props}
      product={product}
      proCustomer={proCustomer}
      amazonCart={amazonCart}
      isLoading={false}
      quickAddToCartState={quickAddToCartState}
      variantAlreadyInCart={variantAlreadyInCart}
      cartEnabled={cartEnabled}
      showPrice={showPrice}
      mikmakEnabled={mikmakEnabled}
    />
  );
};

const InlineProductCardActive = (
  props: InlineProductCardProps & {
    product: InlineProductCardProduct;
    isLoading: false;
  }
) => {
  const {
    product,
    isLoading,
    className,
    showDetailsLink,
    handleAdd,
    proCustomer = false,
    amazonCart = false,
    quickAddToCartState,
    variantAlreadyInCart,
    showPrice = true,
    cartEnabled = true,
    mikmakEnabled,
  } = props;

  const proTest = !!product?.proOnly && !proCustomer;

  const handleAddClick = () => {
    const id = product.firstAvailableVariantId;
    if (!id) {
      return;
    }

    if (handleAdd) {
      handleAdd({
        id,
        quantity: 1,
      });
    }
  };

  return (
    <div
      className={clsx(
        styles.inlineProductCard,
        className,
        mikmakEnabled && 'product-quickview'
      )}
    >
      <div className={styles.inlineProductCard__imageWrapper}>
        {product?.image && (
          <Image
            className={styles.inlineProductCard__image}
            src={product.image}
            width={57}
            height={74}
            alt={product.images?.[0]?.alt || product.title}
            title={product.images?.[0]?.alt || product.title}
          />
        )}
      </div>
      <div className={styles.inlineProductCard__content}>
        <ProductCardBadge
          product={product}
          className={styles.inlineProductCard__badge}
        />

        {product?.systemName && (
          <p
            className={clsx(
              styles.inlineProductCard__systemName,
              'text-utility-utility-small-mobile',
              'text-utility-utility-desktop'
            )}
          >
            {product.systemName}
          </p>
        )}

        {product?.title && (
          <Link
            className={clsx(
              styles.inlineProductCard__title,
              'text-body-2-bold-mobile',
              'text-body-1-bold-desktop'
            )}
            href={product.url}
          >
            {product.title}
          </Link>
        )}

        {product?.slogan && (
          <p
            className={clsx(
              styles.inlineProductCard__slogan,
              'text-body-small-desktop text-body-small-mobile'
            )}
          >
            {product.slogan}
          </p>
        )}

        {!proTest && showPrice && (
          <ProductPrice
            price={product.price}
            type="unit"
            isLoading={isLoading}
          />
        )}
        {showDetailsLink && (
          <Button
            modifiers="text"
            className={clsx(
              styles.inlineProductCard__showDetails,
              'text-body-small-mobile text-body-small-desktop'
            )}
            href={product.url}
          >
            <Trans id="product.fullDetails" message="Full details" />
          </Button>
        )}
      </div>

      <div className={styles.inlineProductCard__ctaWrapper}>
        <AddToCart
          product={{
            ...product,
            barcode: product?.barcode,
          }}
          proCustomer={proCustomer}
          amazonCart={amazonCart}
          placement="inline_card"
          addToCart={handleAddClick}
          state={quickAddToCartState}
          variantAlreadyInCart={variantAlreadyInCart}
          cartEnabled={cartEnabled}
          mikmakEnabled={mikmakEnabled}
        />
      </div>
    </div>
  );
};

const InlineProductCardLoading = (props: InlineProductCardProps) => {
  const { product, className } = props;

  return (
    <div className={clsx(styles.inlineProductCard, className, 'is-loading')}>
      <div className={styles.inlineProductCard__imageWrapper}>
        <div className={styles.inlineProductCard__image}></div>
      </div>
      <div className={styles.inlineProductCard__content}>
        <p
          className={clsx(
            styles.inlineProductCard__systemName,
            'text-utility-utility-small-mobile',
            'text-utility-utility-desktop'
          )}
          aria-hidden
        >
          {product?.systemName ?? (
            <Trans id="accessibility.loading" message="Loading..." />
          )}
        </p>

        <p
          className={clsx(
            styles.inlineProductCard__title,
            'text-body-2-bold-mobile',
            'text-body-1-bold-desktop'
          )}
          aria-hidden
        >
          {product?.title ?? (
            <Trans id="accessibility.loading" message="Loading..." />
          )}
        </p>

        <p className={productPriceStyles['productPrice--unit']} aria-hidden>
          <span className={styles.inlineProductCard__price}>
            <Trans id="accessibility.loading" message="Loading..." />
          </span>
        </p>
      </div>

      <div className={styles.inlineProductCard__ctaWrapper}>
        <Button type={'button'} modifiers={['mini']} disabled>
          <Trans id="product.addToCart" message="ADD TO BAG" />
        </Button>
      </div>
    </div>
  );
};
