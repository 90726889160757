import { Trans } from '@lingui/macro';
import { Button } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useMediaMatch } from 'rooks';
import type { ColorSelectorTabs } from 'components/colorSelector/ColorSelector';
import { ColorSelectorShade } from 'components/colorSelector/ColorSelectorShade';
import styles from './ColorSelectorTab.module.scss';

/**
 * ColorSelectorTab Component.
 * @param props -  Component props.
 */
export const ColorSelectorTab = (
  props: ColorSelectorTabs & {
    isActive: boolean;
    setActiveProductTitle(title: string): void;
  }
) => {
  const { isActive, products, description, setActiveProductTitle } = props;

  const shadesElement: HTMLDivElement[] = [];
  const [activeGridIndex, setActiveGridIndex] = useState(-1);
  const [tabScrollLocked, setTabScrollLocked] = useState(false);
  const hasActiveShade = activeGridIndex !== -1;

  const scrollLockTimeout = useRef<NodeJS.Timeout>();

  // To fix CSS bugs on exactly 1024px, this should be 1px lower than 'l' breakpoint
  const isSmallWindow = useMediaMatch('(max-width: 767px)');

  useEffect(() => {
    /**
     * Watch activeGridIndex and scroll shade grid into view.
     */
    setTimeout(() => {
      if (!isSmallWindow) return;

      if (activeGridIndex === -1) {
        clearTimeout(scrollLockTimeout?.current);
        setTabScrollLocked(false);
        return;
      }

      // Make sure activeProductTitle is set
      // When surprise me button is used for example.
      const { title: activeProductTitle = '' } =
        products[activeGridIndex] ?? {};
      setActiveProductTitle(activeProductTitle);

      shadesElement[activeGridIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });

      // Set scroll lock with `overflow:hidden` after scrolled into view.
      scrollLockTimeout.current = setTimeout(
        () => setTabScrollLocked(true),
        500
      );
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGridIndex]);

  /** Clear active grid index if tab is not active  */
  useEffect(() => {
    if (!isActive) {
      setActiveGridIndex(-1);
    }
  }, [isActive]);

  const generateRandomShade = () => {
    const randomShadeIndex = Math.floor(Math.random() * 40);
    setActiveGridIndex(randomShadeIndex);
    setActiveProductTitle(products[randomShadeIndex].title);
  };

  return (
    <div
      className={clsx(
        styles.colorSelectorTab__container,
        'container',
        !isActive && styles.colorSelectorTab__hide,
        tabScrollLocked && styles.colorSelectorTab__noOverflow
      )}
    >
      <div className={styles.colorSelectorTab}>
        {products.map((product, index) => {
          return (
            <ColorSelectorShade
              key={index}
              shadesElement={shadesElement}
              product={product}
              gridIndex={index}
              activeGridIndex={activeGridIndex}
              setActiveGridIndex={setActiveGridIndex}
              setActiveProductTitle={setActiveProductTitle}
              isSmallWindow={isSmallWindow}
            />
          );
        })}
      </div>

      {!hasActiveShade && (
        <>
          <h2 className={styles.colorSelectorTab__description}>
            {description}
          </h2>
          <p
            className={clsx(
              styles.colorSelectorTab__helpText,
              'text-body-1-desktop text-body-1-mobile'
            )}
          >
            <Trans id="colorSelector.helpText">Click a shade to discover</Trans>
          </p>

          <Button
            className={styles.colorSelectorTab__surpriseButton}
            modifiers="white"
            type="button"
            onClick={generateRandomShade}
          >
            <Trans id="colorSelector.surpriseMe">Surprise Me</Trans>
          </Button>
        </>
      )}
    </div>
  );
};
