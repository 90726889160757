import { t, Trans } from '@lingui/macro';
import { Button } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import type { CSSProperties } from 'react';
import FocusLock from 'react-focus-lock';
import { useContext, useContextSelector } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import type { ColorSelectorProduct } from 'components/colorSelector/ColorSelector';
import { OverlayContext } from 'components/overlay/OverlayContext';
import IconClose from 'icons/misc/close.svg';
import { colorBrightness } from 'utils/helpers';
import { useProductsByTitleQuery } from 'utils/hooks/useProductsByTitleQuery';
import styles from './ColorSelectorShade.module.scss';

/**
 * ColorSelectorShade Component.
 */
export const ColorSelectorShade = ({
  product,
  gridIndex,
  activeGridIndex,
  shadesElement,
  setActiveGridIndex,
  setActiveProductTitle,
  isSmallWindow,
}: {
  product: ColorSelectorProduct;
  gridIndex: number;
  activeGridIndex: number;
  shadesElement: HTMLDivElement[];
  setActiveGridIndex(activeGridIndex: number): void;
  setActiveProductTitle(title: string): void;
  isSmallWindow: boolean;
}) => {
  // Is Other Overlay Active.
  const isOtherOverlayActive = useContextSelector(
    OverlayContext,
    ({ active }) => !!active[0]
  );

  const enabled = gridIndex === activeGridIndex;

  const gridSize = {
    desktop: {
      columns: 10,
      rows: 4,
      columnsSpan: 4,
    },
    mobile: {
      columns: 8,
      rows: 5,
      columnsSpan: 4,
    },
  };
  const desktopShadeGridWidth = 100 / gridSize.desktop.columns;
  const mobileShadeGridWidth = 100 / gridSize.mobile.columns;
  const desktopShadeGridHeight = 100 / gridSize.desktop.rows;
  const mobileShadeGridHeight = 100 / gridSize.mobile.rows;

  let expandLeft =
    gridIndex % gridSize.desktop.columns >
    gridSize.desktop.columns - gridSize.desktop.columnsSpan
      ? '60%'
      : 'unset';

  let left = `${
    (gridIndex % gridSize.desktop.columns) * desktopShadeGridWidth
  }%`;
  let top =
    Math.floor(gridIndex / gridSize.desktop.columns) * desktopShadeGridHeight +
    '%';

  if (isSmallWindow) {
    expandLeft = gridIndex % gridSize.mobile.columns > 4 ? '50%' : 'unset';
    left = `${(gridIndex % gridSize.mobile.columns) * mobileShadeGridWidth}%`;
    top =
      Math.floor(gridIndex / gridSize.mobile.columns) * mobileShadeGridHeight +
      '%';
  }

  const openProductDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (activeGridIndex !== -1) {
      setActiveGridIndex(-1);
      setActiveProductTitle('');
      return;
    }

    setActiveGridIndex(gridIndex);
    setActiveProductTitle(product.title);
  };

  return (
    <div
      className={styles.colorSelectorShade}
      style={
        {
          '--left': left,
          '--expand-left': expandLeft,
          '--top': top,
          '--shade-color': product.shadeColor,
        } as CSSProperties
      }
    >
      <button
        type="button"
        onClick={openProductDetails}
        className={styles.colorSelectorShade__button}
        aria-label={t({
          id: 'colorSelector.open',
          message: 'View details for this shade',
        })}
      />

      <FocusLock
        disabled={!enabled || isOtherOverlayActive}
        returnFocus
        persistentFocus={true}
      >
        <div
          className={clsx(
            styles.colorSelectorShade__infoContainer,
            enabled && 'expand'
          )}
          ref={(shadeElement) =>
            shadeElement && shadesElement.push(shadeElement)
          }
        >
          {enabled && (
            <ColorSelectorShadeDetails
              product={product}
              gridIndex={gridIndex}
              activeGridIndex={activeGridIndex}
              setActiveGridIndex={setActiveGridIndex}
              setActiveProductTitle={setActiveProductTitle}
            />
          )}
        </div>
      </FocusLock>
    </div>
  );
};

const ColorSelectorShadeDetails = ({
  product,
  gridIndex,
  activeGridIndex,
  setActiveGridIndex,
  setActiveProductTitle,
}: {
  product: ColorSelectorProduct;
  gridIndex: number;
  activeGridIndex: number;
  setActiveGridIndex(activeGridIndex: number): void;
  setActiveProductTitle(title: string): void;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);
  const brightness = colorBrightness(product.shadeColor) ?? 0;
  const theme = brightness > 127.5 ? 'dark' : 'light';
  const enabled = gridIndex === activeGridIndex;
  const { data: products, isLoading } = useProductsByTitleQuery(
    product.title,
    locale
  );

  // Toggle Active Header
  const { toggle: toggleOverlay } = useContext(OverlayContext);

  const availableIn =
    products?.map((product) => product.systemName).join(', ') ?? '';

  const gridProduct = products?.find(
    (productRes) => productRes.handle === product.handle
  );

  /**
   * If product title has ' - ' use the fist part of title in query
   */
  const [sanitizedTitle] = product.title.split(' - ');

  const images = gridProduct?.images;
  const packshotImage = images?.find((image) => {
    return image.alt.includes('-pack_shot');
  });
  const backgroundImage = images && images[2];

  /**
   * If the product is loaded and there's no background image, fire
   * `setImageLoaded` anyway so that opened block as some content visible.
   */
  if (!isLoading && !backgroundImage?.src && !imageLoaded) {
    setImageLoaded(true);
  }

  const closeActiveShade = () => {
    setActiveGridIndex(-1);
    setActiveProductTitle('');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      closeActiveShade();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        styles.colorSelectorShade__info,
        imageLoaded && !isLoading && enabled && 'visible'
      )}
      onKeyDown={handleKeyDown}
      data-theme={theme}
    >
      <button
        className={styles.colorSelectorShade__close}
        type="button"
        onClick={closeActiveShade}
        aria-label={t({
          id: 'accessibility.close',
          message: 'Close',
        })}
      >
        <IconClose />
      </button>

      {backgroundImage?.src && (
        <Image
          className={styles.colorSelectorShade__backgroundImage}
          src={backgroundImage.src}
          alt={backgroundImage.alt}
          title={backgroundImage.alt}
          fill
          sizes="(max-width: 1024px) 100vw, 522px"
          onLoadingComplete={() => {
            setImageLoaded(true);
          }}
        />
      )}

      <div className={styles.colorSelectorShade__details}>
        {packshotImage?.src && (
          <Image
            className={styles.colorSelectorShade__packshotImage}
            src={packshotImage.src}
            alt={packshotImage.alt}
            title={packshotImage.alt}
            width={300}
            height={385}
          />
        )}

        <div className={styles.colorSelectorShade__detailsContent}>
          <p
            className={clsx(
              'text-body-1-bold-desktop text-body-1-bold-mobile',
              styles.colorSelectorShade__detailsTitle
            )}
          >
            {sanitizedTitle}
          </p>
          <p
            className={clsx(
              'text-body-1-desktop text-body-1-mobile',
              styles.colorSelectorShade__detailsAvailableIn
            )}
          >
            <Trans id="colorSelector.availableIn" values={{ availableIn }}>
              {`Available in: ${availableIn}`}
            </Trans>
          </p>
          <Button
            className={styles.colorSelectorShade__detailsButton}
            modifiers={theme === 'light' ? 'white' : ''}
            type="button"
            onClick={() => {
              toggleOverlay({
                ignoreDismissed: true,
                namespace: 'quickShopTray',
              });
            }}
          >
            <Trans id="colorSelector.quickShop">Quick Shop</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};
