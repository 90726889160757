import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import Image from 'next/image';
import { InlineProductCard } from '../../../components/cards/inlineProduct/InlineProductCard';
import type { ProductCardProduct } from '../../../components/cards/product/ProductCard';
import { QuickAddToCartStates } from '../../../components/ui/addToCart/AddToCart';

import styles from './QuickShopTray.module.scss';

export type QuickShopTrayViewProps = {
  title: string;
  products?: ProductCardProduct[];
  proCustomer: boolean;
  amazonCart: boolean;
  loading: boolean;
  cartLoading: boolean;
  handleAdd: (payload: {
    productId: string;
    id: string;
    quantity: number;
  }) => void;
  quickAddedProductId: string;
  cartVariantsIds: string[];
};

/**
 * QuickShopTrayView Component.
 * Listing of products inline cards.
 */
export const QuickShopTrayView = ({
  title,
  products = [],
  proCustomer,
  amazonCart,
  loading,
  cartLoading,
  handleAdd,
  quickAddedProductId,
  cartVariantsIds,
}: QuickShopTrayViewProps) => {
  const nonProOnlyProducts = products.filter((product) => !product.proOnly);
  const proOnlyProducts = products.filter((product) => product.proOnly);
  const [firstProduct] = products;

  return (
    <div className={styles.quickShopTray}>
      <QuickShopTrayHeader
        title={title}
        loading={loading}
        product={firstProduct}
      />

      {loading && (
        <div className={styles.quickShopTray__list}>
          {[...Array(3).keys()].map((key) => {
            return (
              <InlineProductCard
                key={key}
                isLoading={loading}
                proCustomer={proCustomer}
                amazonCart={amazonCart}
                showDetailsLink={true}
                className={styles.quickShopTray__product}
              />
            );
          })}
        </div>
      )}

      {!products.length && !loading && (
        <div className={styles.quickShopTray__list}>
          <p
            className={clsx(
              styles.quickShopTray__noResults,
              'text-body-2-bold-mobile text-body-2-bold-desktop'
            )}
          >
            <Trans id="quickShopTray.noResults">No products found!</Trans>
          </p>
        </div>
      )}

      {!loading && nonProOnlyProducts.length > 0 && (
        <div className={styles.quickShopTray__list}>
          {nonProOnlyProducts?.map((product, index) => (
            <QuickShopTrayProduct
              key={index}
              product={product}
              proCustomer={proCustomer}
              amazonCart={amazonCart}
              handleAdd={handleAdd}
              quickAddedProductId={quickAddedProductId}
              cartLoading={cartLoading}
              cartVariantsIds={cartVariantsIds}
            />
          ))}
        </div>
      )}

      {!loading && proOnlyProducts.length > 0 && (
        <div className={styles.quickShopTray__proOnly}>
          <p
            className={clsx(
              styles.quickShopTray__proOnlyTitle,
              'text-body-2-bold-mobile text-body-2-bold-desktop'
            )}
          >
            <Trans id="quickShopTray.proOnly">
              Only available to professionals
            </Trans>
          </p>
          <div className={styles.quickShopTray__list}>
            {proOnlyProducts?.map((product, index) => (
              <QuickShopTrayProduct
                key={index}
                product={product}
                proCustomer={proCustomer}
                amazonCart={amazonCart}
                handleAdd={handleAdd}
                quickAddedProductId={quickAddedProductId}
                cartLoading={cartLoading}
                cartVariantsIds={cartVariantsIds}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * QuickShopTrayHeader Component.
 * Shows the product title and color image.
 */
const QuickShopTrayHeader = ({
  title,
  product,
  loading,
}: {
  title: string;
  product?: ProductCardProduct;
  loading: boolean;
}) => {
  // Get the swatch image for the product.
  // Always 3th image in product gallery.
  const image = product?.images?.length && product?.images[2];

  if (!title) return null;

  return (
    <div className={styles.quickShopTray__header}>
      {image && !loading && (
        <Image
          className={styles.quickShopTray__headerImage}
          src={image.src}
          width={25}
          height={25}
          title={image.title || image.alt}
          alt={image.alt}
        />
      )}

      {loading && (
        <div
          className={clsx(styles.quickShopTray__headerImage, 'loading')}
        ></div>
      )}
      <p className="text-heading-h5-mobile text-heading-h5-desktop">{title}</p>
    </div>
  );
};

/**
 * QuickShopTrayProduct Component.
 * Returns InlineProductCard with reformatted product.
 * Passed systemName as title and an empty systemName.
 */
const QuickShopTrayProduct = ({
  product,
  proCustomer,
  amazonCart,
  handleAdd,
  quickAddedProductId,
  cartLoading,
  cartVariantsIds,
}: {
  product: ProductCardProduct;
  proCustomer: boolean;
  amazonCart: boolean;
  handleAdd: (payload: {
    productId: string;
    id: string;
    quantity: number;
  }) => void;
  quickAddedProductId: string;
  cartLoading: boolean;
  cartVariantsIds?: string[];
}) => {
  const { systemName, ...restProductProps } = product;

  let quickAddButtonState = QuickAddToCartStates.Active;

  if (cartLoading && quickAddedProductId === product.id) {
    quickAddButtonState = QuickAddToCartStates.Disabled;
  } else if (cartLoading) {
    quickAddButtonState = QuickAddToCartStates.Passive;
  }

  const variantAlreadyInCart = cartVariantsIds?.includes(
    product.firstAvailableVariantId ?? ''
  );

  return (
    <InlineProductCard
      product={{
        ...restProductProps,
        title: systemName,
        systemName: '',
      }}
      proCustomer={proCustomer}
      amazonCart={amazonCart}
      showDetailsLink={true}
      handleAdd={({ id, quantity }) =>
        handleAdd({ productId: product.id, id, quantity })
      }
      className={styles.quickShopTray__product}
      quickAddToCartState={quickAddButtonState}
      variantAlreadyInCart={variantAlreadyInCart}
    />
  );
};

export default QuickShopTrayView;
